import React from "react"
import styles from "./ItemSelectionModal.module.scss"
import classNames from "classnames"
import { Button, Col, Modal, Row, Table } from "react-bootstrap"

const ItemSelectionModal = ({
  title = "Select Item",
  tableData,
  showModal,
  closeModal,
  updateItem,
}: ItemSelectionModalProps) => {

  const tableHeadings = {
    Material: "material",
    Description: "description",
    "Cost (£)": "cost",
    "": null,
  };

  const selectItem = (itemKey: number) => {
    updateItem(itemKey);
  }

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Table responsive striped bordered hover size="sm" className={styles.maxWidth}>
              <thead>
                <tr>
                  {Object.keys(tableHeadings).map((heading, index) => (
                    <td key={index}>{heading}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData && tableData.map((data, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.values(tableHeadings).map((key, colIndex) => (
                      key && <td key={colIndex}>
                        <input
                          readOnly
                          type="text"
                          value={data[key]} />
                      </td>
                    ))}
                    <td key={rowIndex}>
                      <Button id="change-selection-button"
                        variant='success'
                        className={null}
                        onClick={() => selectItem(rowIndex)}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ItemSelectionModal
