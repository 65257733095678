import React from "react"
import Modal from "react-bootstrap/Modal"
import styles from "./ProductInfoModal.module.scss"

const ProductInfoModal = ({ isProductInfoModalOpen, setProductInfoModalOpen, productInfoModalData }: ProductInfoModalProps) => {
  const { description, manufacturer } = productInfoModalData;

  //Each value should be replaced with actual data once we have it
  const modalInformation = [
    {
      title: "Name:",
      value: description,
    },
    {
      title: "Manufacturer:",
      value: manufacturer ?? "",
    },
  ];

  return (
    <Modal
      size="sm"
      show={isProductInfoModalOpen}
      onHide={() => setProductInfoModalOpen(false)}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">
          Product Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.modalContainer}>
          {modalInformation.map(({ title, value }) => (
            <div key={title}>
              <h6 className={styles.title}>{title}</h6>
              <p>{value}</p>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ProductInfoModal;
