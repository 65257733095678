export const getFilteredItems = (response: any, material: string, manufacturer: string, quantity: number, variation: string): APISearchResult[] => {
    const checkExistence = (original: string, expected: string) => original?.toLowerCase().includes(expected);
    const filteredItems = response && response.filter(item => {
        const description: string = !!item && item?.Description;
        const withMaterial = checkExistence(description, material);
        const withVariation = checkExistence(description, variation);
        const withManufacturer = checkExistence(description, manufacturer);
        return !!description &&
            (!!manufacturer ? 
                (!!variation ? (withMaterial && withVariation) && withManufacturer : (withMaterial && withManufacturer))
                :
                (!!variation ? (withMaterial && withVariation) : withMaterial)
            )
    });

    const itemsWithPrices = filteredItems?.filter((item: { Price: string; }) => item.Price != "");

    const sortedResults = itemsWithPrices?.sort((a: { Price: string; }, b: { Price: string; }) => {
        const priceA = Number(a.Price.replace(/[^0-9\.]+/g, ""));
        const priceB = Number(b.Price.replace(/[^0-9\.]+/g, ""));
        return priceA === priceB
            ? 0
            : priceA > priceB
                ? 1
                : -1;
    });

    const emptyResult: APISearchResult[] = [{
        material: material,
        description: `NO ITEM FOUND MATCHING "${!manufacturer ? '' : manufacturer.toUpperCase()} ${material.toUpperCase()}"`,
        quantity: 0,
        cost: 0,
        manufacturer,
    }];

    const finalResult: APISearchResult[] = sortedResults?.map(result => {
        const apiSearchResult: APISearchResult = {
            material: material,
            description: result?.Description ?? `NO ITEM FOUND MATCHING "${!manufacturer ? '' : manufacturer.toUpperCase()} ${material.toUpperCase()}"`,
            quantity: Number(quantity ?? 0),
            cost: Number(result?.Price?.replace(/[^0-9\.]+/g, "") ?? '0') ?? 0,
            manufacturer,
        };

        return apiSearchResult;
    });

    return finalResult ?? emptyResult;
}

export const getCheapestItem = (filteredResults: APISearchResult[], material: string): APISearchResult => {
    const cheapestResult = !!filteredResults && filteredResults[0];

    const manufacturer = cheapestResult?.manufacturer;
    const description = cheapestResult?.description ?? `NO ITEM FOUND MATCHING "${!manufacturer ? '' : manufacturer.toUpperCase()} ${material.toUpperCase()}"`;
    const quantity = cheapestResult?.quantity ?? 0;
    const cost = cheapestResult?.cost ?? 0;

    const finalResult: APISearchResult = {
        material,
        description,
        quantity: Number(quantity) ?? 0,
        cost: Number(cost.toFixed(2)) ?? 0,
        manufacturer,
    };
    return finalResult;
}