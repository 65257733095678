import React, { useContext, useEffect, useState } from "react"
import { CSVLink } from "react-csv";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AuthContext } from "src/context/AuthContext";
import { OrderProps } from "../../MerchantAccordionProps";
import styles from "../OrderModal.module.scss";

const Order = ({ orderConfiguration: { merchantName, merchantEmail, cheapestItems }}: OrderProps) => {

  const csvHeadings = [ "Merchant", "Item", "Description", "Qty", "Cost", "Total", "Merchant Total", ""];
  const [csvFilename, setCsvFilename] = useState("");
  const [subject, setSubject] = useState("");

  const SORT_IT_EMAIL = "info@sortit.ai";

  const { user: { email, name } } = useContext(AuthContext);

  const formatFilename = () => {
    const date = new Date().toISOString().split('T')[0];
    const time = new Date().toTimeString().split(' ')[0].replace(/:/g, '\uA789');
    const customerInitials = name.split(" ").map((n)=>n[0]).join("_");
    return `${date}_${time}_SIAI_${merchantName}-1-${customerInitials}-Quote`
  };

  const buildDataForCSV = (data) => {
    return data.map(({ material, description, quantity, cost, total }, index, arr) => [
            merchantName,
            material,
            description,
            quantity.toString(),
            cost.toString(),
            total.toString(),
            ...(index === arr.length - 1 ? [`${merchantName} Total`, arr.reduce((acc, { total }) => Number(acc) + Number(total), 0).toFixed(2)] : [])
        ])
  }

  useEffect(() => {
    const date = new Date().toISOString().split('T')[0];
    const time = new Date().toTimeString().substring(0, 8).replace(/:/g, '-');
    const projectName = "Breezehurst Farm".match(/[^aeiou\s]/gi).slice(0, 3).join('').toUpperCase();
    const merchant = merchantName.match(/[^aeiou\s]/gi).slice(0, 3).join('').toUpperCase();
    const numberOfOrders = cheapestItems.length;
    setSubject(`Order from SORT IT AI: ${date}_${time}_${projectName}_SIAI_C12_${merchant}_BM_W_000${numberOfOrders}_V1.00`)
  }, [])

  const customerMessage = `
    Dear ${merchantName}.

    I am placing an order from SORT IT on ${new Date().toLocaleDateString()}.

    The order can be found in attached .csv.

    Please could you get back to me with delivery
    quotes and any further information.

    Thanks in advance,

    ${name}
    `
  return (
    <div className="d-flex flex-column">
        <p className={styles.subject}>
            <span className="font-weight-bold">Subject:</span>{" "}
            <span>{subject}</span>
        </p>
      <p>
        <span className="font-weight-bold">From:</span> {SORT_IT_EMAIL}
      </p>
      <p>
        <span className="font-weight-bold">To:</span>{" "}
        <span className={styles.toEmails}>{merchantEmail}; {email}</span>
      </p>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            <span className="font-weight-bold">CC:</span> {SORT_IT_EMAIL}
          </Form.Label>
          <div className={styles.attachementContainer}>
            <CSVLink
              data={[csvHeadings, ...buildDataForCSV(cheapestItems)]}
              filename={csvFilename}
              onClick={() => setCsvFilename(formatFilename)}
            >
              <Button
                variant="outline-dark"
                className={styles.attachementButton}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="20px"
                  width="25px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke-width="2"
                    d="M22,12 C22,12 19.0000009,15.0000004 13.0000004,21.0000004 C6.99999996,27.0000004 -2.00000007,18.0000004 3.99999994,12.0000004 C9.99999996,6.00000037 9,7.00000011 13,3.00000008 C17,-0.999999955 23,4.99999994 19,9.00000005 C15,13.0000002 12.0000004,16.0000007 9.99999995,18.0000004 C7.99999952,20 5,17 6.99999995,15.0000004 C8.99999991,13.0000007 16,6 16,6"
                  ></path>
                </svg>
              </Button>
            </CSVLink>
          </div>
          <Form.Control value={customerMessage} as="textarea" rows={14} />
        </Form.Group>
      </Form>
    </div>
  )
}

export default Order
