import React, { useState, useEffect, memo, useContext } from "react";
import { Container } from "react-bootstrap";
import ProgressIndicator from "./ProgressIndicator";
import styles from "./AdvertisementBanner.module.scss";
import { AuthContext } from "src/context/AuthContext";
import { AuthContextInterface } from "src/context";

const VIDEO_ADS: Array<AdvertisementListItem> = [
    { src: "https://sortitdevstorage.blob.core.windows.net/sortit-ads/2024-02-09_StriveX_30secGoldAd.mp4", duration: 30, canSkipAfterSec: null },
    { src: "https://sortitdevstorage.blob.core.windows.net/sortit-ads/2024-02-09_SIAI_20secSilverAd.mp4", duration: 20, canSkipAfterSec: 10 },
    { src: "https://sortitdevstorage.blob.core.windows.net/sortit-ads/2024-02-09_BwthynYDdol_10secBronzeAd.mp4", duration: 10, canSkipAfterSec: 5 }
];

const AdvertisementBanner = ({ setShowResultsTable }: AdvertisementBannerProps) => {
    const [currentAdIndex, setCurrentAdIndex] = useState(0);
    const currentAd = VIDEO_ADS[currentAdIndex];
    const [isMuted, setIsMuted] = useState(false);
    const { currentUserConfiguration }: AuthContextInterface = useContext(AuthContext);

    useEffect(() => {
        if (currentUserConfiguration?.adsFree) {
            setShowResultsTable(true)
        }
    }, [])

    const canAutoplayWithSound = () => {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.src = currentAd?.src;
            video.muted = false;
            video.volume = 1.0;
            const onVideoEvent = (event) => {
                video.pause();
                resolve(event.type === 'canplaythrough');
            };
            video.oncanplaythrough = onVideoEvent;
            video.onerror = onVideoEvent;
            video.play().catch(onVideoEvent);
        });
    };

    useEffect(() => {
        const checkAutoplayPolicy = async () => {
            const canAutoplay = await canAutoplayWithSound();
            setIsMuted(!canAutoplay);
        };
        checkAutoplayPolicy();
    }, [currentAd?.src]);

    return (
        <Container className={styles.container}>
             <video playsInline key={currentAd?.src} className={styles.media} autoPlay muted={isMuted}>
                <source src={currentAd?.src} type="video/mp4" />
            </video>
            <ProgressIndicator 
              adsList={VIDEO_ADS} 
              currentAdIndex={currentAdIndex} 
              setCurrentAdIndex={setCurrentAdIndex} 
              setShowResultsTable={setShowResultsTable} 
              />
        </Container>
    )
}

// Prevents re-render because of parent update
export default memo(AdvertisementBanner);