import { getCheapestItem, getFilteredItems } from 'src/utils/parseBMJsonFile';
import clubbConcreteBMData from 'src/content/clubbConcrete.json';

export const searchClubbConcreteBM: ApiSearchFunc = async (data: UnleashedSearchProps): Promise<ApiSearchResults> => {
    const { material, quantity, manufacturer, variation } = data;
    const response = await clubbConcreteBMData.ClubbConcrete;
    const filteredItems: APISearchResult[] = getFilteredItems(response, material, manufacturer, quantity, variation);
    const cheapestItem: APISearchResult = getCheapestItem(filteredItems, material);
    const finalResult: ApiSearchResults = {
        material,
        cheapestItem,
        results: filteredItems,
    }
    return finalResult;
}