import { getCheapestItem, getFilteredItems } from 'src/utils/parseBMJsonFile';
import jewsonBMData from 'src/content/jewson.json';

export const searchJewsonBM: ApiSearchFunc = async (data: UnleashedSearchProps): Promise<ApiSearchResults> => {
    const { material, quantity, manufacturer, variation } = data;

    const response = await jewsonBMData.Jewson;
    const filteredItems: APISearchResult[] = getFilteredItems(response, material, manufacturer, quantity, variation);
    const cheapestItem: APISearchResult = getCheapestItem(filteredItems, material);

    const finalResult: ApiSearchResults = {
        material,
        cheapestItem,
        results: filteredItems,
    }

    return finalResult;
}
