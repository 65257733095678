import React, { memo, useEffect, useRef, useState } from "react";
import { ProgressBar, Button } from "react-bootstrap";
import styles from "../AdvertisementBanner.module.scss";

const ProgressIndicator = ({ adsList, currentAdIndex, setCurrentAdIndex, setShowResultsTable }: ProgressIndicatorProps) => {
    const INTERVAL_TIME = 1000;
    const SKIP_KEY = "si";
    const [indicatorTime, setIndicatorTime] = useState(adsList[0]?.duration ?? 0);
    const [showSkipButton, setShowSkipButton] = useState(false);
    const sequenceRef = useRef('');
    const numberOfAds = adsList.length;

      useEffect(() => {
        if (currentAdIndex >= numberOfAds) {
          return;
        }

        const currentAd = currentAdIndex < numberOfAds && adsList[currentAdIndex];
        const isAdSkippable = !!currentAd?.canSkipAfterSec;
        const timeWithoutShowingSkipButton = isAdSkippable ? currentAd.duration - currentAd.canSkipAfterSec : 0;
        const showSkipButton = isAdSkippable && indicatorTime <= timeWithoutShowingSkipButton;
        const doNotShowSkipButton = indicatorTime === 0 || (isAdSkippable && indicatorTime > timeWithoutShowingSkipButton);
        
        setShowSkipButton(currentAdIndex < numberOfAds && showSkipButton && !doNotShowSkipButton);

        const handleInterval = () => {
          if (indicatorTime === 0) {
            const nextAdIndex = currentAdIndex + 1;
            if (nextAdIndex < numberOfAds) {
              setCurrentAdIndex(nextAdIndex); 
              setIndicatorTime(adsList[nextAdIndex].duration);
            } else {
                setShowResultsTable(true)
            }
          } else {
            setIndicatorTime(indicatorTime - 1);
          }
        };

        const intervalId = setInterval(handleInterval, INTERVAL_TIME);
        
        return () => {
          clearInterval(intervalId);
        };
      }, [currentAdIndex, indicatorTime]);


      useEffect(() => {
        const handleKeyDown = ({ key }) => {
          sequenceRef.current += key;
          sequenceRef.current = sequenceRef.current.slice(-2);
          if (sequenceRef.current.toLowerCase() === SKIP_KEY) {
            setCurrentAdIndex(numberOfAds);
            setIndicatorTime(0);
            setShowResultsTable(true)
          }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

      const handleSkip = () => {
        const nextAdIndex = currentAdIndex + 1;
        if (nextAdIndex < numberOfAds) {
          setCurrentAdIndex(nextAdIndex);
          setIndicatorTime(adsList[nextAdIndex].duration);
        } else {
          setIndicatorTime(0);
          setShowResultsTable(true)
        }
      };

    return (
        <>
        <div className={styles.progressIndicatorDetails}>
        <p style={{ color: "black"}}>AD {currentAdIndex + 1} of {numberOfAds}</p>
        {showSkipButton && <Button variant="outline-dark" onClick={handleSkip}>Skip &gt;</Button>}
        <p style={{ color: "black" }}>{indicatorTime} seconds...</p>
        </div>
         <ProgressBar 
           className={styles.progressIndicator} 
           now={adsList[currentAdIndex]?.duration - indicatorTime} 
           max={adsList[currentAdIndex]?.duration}/>
        </>
    )
}

export default memo(ProgressIndicator);