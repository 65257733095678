import CryptoJS from 'crypto-js';
import { getCheapestItem } from 'src/utils/parseBMJsonFile';

export const searchUnleashed: ApiSearchFunc = async (data: UnleashedSearchProps): Promise<ApiSearchResults> => {
    const { material, quantity, manufacturer, variation } = data;
    const urlParam = `product=${material}`;
    const url = `https://api.unleashedsoftware.com/Products?${urlParam}`;

    // CryptoJS is being used in javascript to generate the hash security keys
    // We need to pass the url parameters as well as the key to return a SHA256
    // TODO: hide this!!!
    const apiKey = 'uVsyh3Yg3DBmkrnqkupcJullth2iY7l3BU24UZQNfN25Q5R95XBdg2Zy7xjchP2di5O9qY6U5tRQ194ZhCbxw==';
    var hash = CryptoJS.HmacSHA256(urlParam, apiKey);

    // That hash generated has to be set into base64
    var hash64 = CryptoJS.enc.Base64.stringify(hash);
    const apiAuthId = '5335f2cf-7a68-45fc-9372-f54d7ab7d180';

    const requestInit = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'api-auth-id': apiAuthId,
            'api-auth-signature': hash64,
            'Content-Type': 'application/json'
        }
    };

    const response = await fetch(url, requestInit);
    const searchResults = await response.json();
    const items = searchResults.Items;

    const filteredItems = getFilteredItems(items, material, manufacturer, quantity, variation);
    const cheapestItem = getCheapestItem(filteredItems, material);

    const finalResult: ApiSearchResults = {
        material,
        cheapestItem,
        results: filteredItems,
    };

    return finalResult;
}

export const getFilteredItems = (items: any, material: string, manufacturer: string, quantity: number, variation: string): APISearchResult[] => {
    const checkExistence = (original: string, expected: string) => original?.toLowerCase().includes(expected);
    const firstRuleCode = !!variation ? items.filter(item => checkExistence(item.ProductCode, variation)) : items
    const secondRuleCode = !!variation ? 
    items.filter(item => checkExistence(item.ProductCode, manufacturer) && checkExistence(item.ProductCode, variation)) 
    : 
    items.filter(item => checkExistence(item.ProductCode, manufacturer));

    const firstRuleDescription = !!variation ? items.filter(item => checkExistence(item.ProductDescription, variation)) : items;
    const secondRuleDescription = !!variation ? 
    items.filter(item => checkExistence(item.ProductDescription, manufacturer) && checkExistence(item.ProductDescription, variation)) 
    : 
    items.filter(item => checkExistence(item.ProductDescription, manufacturer))

    const manufacturerInCode = !!!manufacturer ? firstRuleCode : secondRuleCode
    const manufacturerInDescription = !!!manufacturer ? firstRuleDescription : secondRuleDescription

    
    const itemsFilteredByManufacturer = [
        ...manufacturerInCode,
        ...manufacturerInDescription,
    ]
    
    const itemsWithPrices = itemsFilteredByManufacturer.filter(item => item.DefaultSellPrice > 0)

    const sortedResults = itemsWithPrices.sort((a, b) => {
        return (a.DefaultSellPrice > b.DefaultSellPrice) ? 1 : -1;
    });

    const emptyResult: APISearchResult[] = [{
        material: material,
        description: `NO ITEM FOUND MATCHING "${!manufacturer ? '' : manufacturer.toUpperCase()} ${material.toUpperCase()}"`,
        quantity: 0,
        cost: 0,
        manufacturer,
    }];

    const finalResult: APISearchResult[] = sortedResults?.map(result => {
        const apiSearchResult: APISearchResult = {
            material: material,
            description: result?.ProductDescription ?? `NO ITEM FOUND MATCHING "${!manufacturer ? '' : manufacturer.toUpperCase()} ${material.toUpperCase()}"`,
            quantity: Number(quantity ?? 0),
            cost: result.DefaultSellPrice ?? 0,
            manufacturer,
        };

        return apiSearchResult;
    });

    return finalResult ?? emptyResult;
}