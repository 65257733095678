import React from "react"

const ResponsiveAmount = ({ amount }) => {
  const formatNumber = number => {
    const scales = [
      { limit: 1e6, divisor: 1e6, suffix: "m", decimalPlaces: 1 },
      { limit: 1e3, divisor: 1e3, suffix: "k", decimalPlaces: 1 },
      { limit: 0, divisor: 1, suffix: "", decimalPlaces: 2 },
    ]
    const scale = scales.find(s => number >= s.limit)
    const scaledNumber = Math.floor((number / scale.divisor) * 10 ** scale.decimalPlaces) /
      10 ** scale.decimalPlaces
    return `£ ${scaledNumber.toFixed(scale.decimalPlaces)}${scale.suffix}`;
  }
   return (
    <div>
      <span className="d-none d-lg-block">£ {amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
      <span className="d-block d-lg-none">{formatNumber(amount)}</span>
    </div>
    )
}
export default ResponsiveAmount;