import blm from "images/merchants/blm.png"
import discountBM from "images/merchants/discountBM.png"
import medwayBM from "images/merchants/medway.jpg"
import championTimber from "images/merchants/championTimber.png"
import jewson from "images/merchants/jewson.png"
import lords from "images/merchants/lords.png"
import maidstone from "images/merchants/maidstone.png"
import selco from "images/merchants/selco.png"
import clubbConcrete from "images/merchants/clubbConcrete.jpg"
import { searchUnleashed } from 'src/merchantAPIs/unleashed';
import { searchDiscountBM } from 'src/merchantAPIs/discountBM';
import { searchMedwayBM } from 'src/merchantAPIs/medwayBM';
import { searchChampionTimberBM } from 'src/merchantAPIs/championTimberBM';
import { searchJewsonBM } from 'src/merchantAPIs/jewsonBM';
import { searchLordsBM } from 'src/merchantAPIs/lordsBM';
import { searchMaidstoneBM } from 'src/merchantAPIs/maidstoneBM';
import { searchSelcoBM } from 'src/merchantAPIs/selcoBM';
import { searchClubbConcreteBM } from "./clubbConcreteBM"

export const merchantsList: Merchant[] = [
    {
        merchantName: "BLM",
        merchantBrandPicture: blm,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchUnleashed,
    },
    {
        merchantName: "Discount BM",
        merchantBrandPicture: discountBM,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchDiscountBM,
    },
    {
        merchantName: "Medway BM",
        merchantBrandPicture: medwayBM,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchMedwayBM,
    },
    {
        merchantName: "Champion Timber",
        merchantBrandPicture: championTimber,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchChampionTimberBM,
    },
    {
        merchantName: "Jewson",
        merchantBrandPicture: jewson,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchJewsonBM,
    },
    {
        merchantName: "Lords",
        merchantBrandPicture: lords,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchLordsBM,
    },
    {
        merchantName: "Maidstone",
        merchantBrandPicture: maidstone,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchMaidstoneBM,
    },
    {
        merchantName: "Selco",
        merchantBrandPicture: selco,
        merchantEmail: "info@sortit.ai",
        searchFunction: searchSelcoBM,
    },
    {
        merchantName: "Clubb Concrete",
        merchantBrandPicture: clubbConcrete,
        merchantEmail: "tmulton@clubbconcrete.co.uk",
        searchFunction: searchClubbConcreteBM
    }
];