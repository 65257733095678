import React, { useState } from "react"
import { CSVLink } from "react-csv";
import styles from "./MerchantAccordion.module.scss"
import classNames from "classnames"
import { Accordion, Button, Card, Image, Table } from "react-bootstrap"
import ItemSelectionModal from "../ItemSelectionModal";
import ResponsiveAmount from "./ResponsiveAmount";
import ProductInfoModal from "./ProductInfoModal";
import { transcriptManufacturerMatcher } from "src/utils/voiceSearch-utils";
import FilterModal from "./FilterModal";
import OrderModal from "./OrderModal";
import {
   MerchantAccordionProps, 
   MerchantAccordionRows, 
   MerchantAccordionProduct, 
   FormattedAccordionRowData, 
   OrderConfiguration,
} from "./MerchantAccordionProps";

const MerchantAccordion = ({
  rows,
}: MerchantAccordionProps) => {
  const csvHeadings = [ "Merchant", "Item", "Description", "Qty", "Cost", "Total", "Merchant Total", ""];
  const merchantOptions = ["Cost", "Stocked", "In Stock", "Delivery"];
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('brick');
  const [accordionData, _] = useState<MerchantAccordionRows>(rows);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ItemSelectionTableData[]>([]);
  const [filename, setFilename] = useState("");
  const [isProductInfoModalOpen, setProductInfoModalOpen] = useState<boolean>(false);
  const [productInfoModalData, setProductInfoModalData] = useState<MerchantAccordionProduct>([]);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [formattedAccordionData, setFormattedAccordiondData] = useState([]);
  const [isSortingActive, setSortingActive] = useState(true);
  const [isOrderModalOpen, setOrderModalOpen] = useState<boolean>(false);
  const [orderConfiguration, setOrderConfiguration] = useState<OrderConfiguration>({ merchantName: "", merchantEmail: "", cheapestItems: [] });

  const formatFilename = () => {
    const date = new Date().toISOString().split('T')[0];
    const time = new Date().toTimeString().split(' ')[0].replace(/:/g, '\uA789');
    return `${date}_${time}_SIAI_BuildingMaterials`
  };

  const updateSelectedItem = (newItemKey: number) => {
    const merchantRow = accordionData[selectedMerchant];
    const cheapestItems = merchantRow.cheapestItems;
    const merchantMaterialProducts = merchantRow.tableData[selectedMaterial];

    const oldItemIndex = cheapestItems.findIndex((item) => item.material === selectedMaterial)
    const newItem = merchantMaterialProducts[newItemKey];
    cheapestItems[oldItemIndex] = newItem;

    setShowModal(false);
  }

  const handleChangeSelection = (material: string) => {
    setSelectedMaterial(material);
    updateModalData(material);

    setShowModal(true);
  }

  const tableHeadings = {
    "": "material",
    Description: "description",
    Qty: "quantity",
    "Cost (£)": "cost",
    "Total (£)": "total",
    "Change Item": null,
  }

  const updateModalData = ((material: string) => {
    const products = accordionData[selectedMerchant].tableData;
    const materialData = products[material];

    const data: ItemSelectionTableData[] = materialData.map((product) => {
      const itemData: ItemSelectionTableData = {
        cost: product.cost,
        description: product.description,
        material: product.material,
      }

      return itemData;
    })

    setModalData(data);
  });

const handleInfoIcon = (data) => {
    const modalConfiguration = {
      ...data, 
      manufacturer: transcriptManufacturerMatcher(data.description)
    };
    setProductInfoModalOpen(true);
    setProductInfoModalData(modalConfiguration);
};

const buildDataForCSV = (data: FormattedAccordionRowData[]) => {
  return data.flatMap(({ key, cheapestItems}) =>
    cheapestItems.map(({ material, description, quantity, cost, total }, index, arr) => [
          key,
          material,
          description,
          quantity.toString(),
          cost.toString(),
          total.toString(),
          ...(index === arr.length - 1 ? [`${key} Total`, arr.reduce((acc, { total }) => Number(acc) + Number(total), 0).toFixed(2)] : [])
      ])
  );
}

  return (
    <>
    <div className={styles.actionsContainer}>
    <Button 
      onClick={() => setFilterModalOpen(true)} 
      variant="outline-dark" 
      className={classNames(styles.filterButton, isSortingActive ? styles.active : styles.disabled)}
      >
    <svg
      className={styles.activeButton}
      stroke="currentColor"
      fill={isSortingActive ? "#ffffff" : "#000000"}
      stroke-width="0"
      viewBox="0 0 512 512"
      height="20"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z"></path>
      </svg>
    </Button>
    <CSVLink 
     data={[csvHeadings, ...buildDataForCSV(formattedAccordionData)]} 
     filename={filename} 
     onClick={() => setFilename(formatFilename)}
    >
      <Button variant="outline-dark" className={styles.downloadButton}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="grey" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
        </svg>
      </Button>
    </CSVLink>
    </div>
    <div className={styles.merchantOptions}>
    {merchantOptions.map((option) => <span className={styles.merchantOption}>{option}</span>)}
    </div>
    <div className={styles.merchantsWrapper}>
    {formattedAccordionData?.map(({ key, merchantBrandPicture, merchantEmail, merchantTotal, itemsStocked, totalItems, cheapestItems }, _) => (
        <Accordion>
          <Card key={key} className={styles.merchantCard}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={key}
              onClick={() => setSelectedMerchant(key)}
            >
              <div className={styles.merchantContainer}>
              <div className={styles.merchantContainerHeading}>
                <div className={styles.merchantInformation}>
                <Image 
                  className={styles.merchantImage} 
                  src={merchantBrandPicture} 
                />
                <div className={styles.merchantName}>{key}</div>
                </div>
                <Button 
                onClick={(e) => {
                  e.stopPropagation();
                  setOrderModalOpen(true);
                  setOrderConfiguration({ merchantName: key, merchantEmail, cheapestItems })
                }} 
                variant="success"
                >
                  Order
                </Button>
              </div>
              <div className={styles.separatedLine} />
              <div className={styles.merchantDetails}>
              <span className={styles.merchantDetail}>
                  <ResponsiveAmount amount={Number(merchantTotal)} />
              </span>
                <span className={styles.merchantDetail}>
                  {itemsStocked}/{totalItems}
                </span>
                <span className={styles.merchantDetail}>N/A</span>
                <span className={styles.merchantDetail}>
                  <input className={styles.deliveryCheckbox} type="checkbox" />
                </span>
              </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={key}>
              <Card.Body className={styles.merchantCardBody}>
              <Table 
                responsive 
                striped 
                hover 
                size="sm" 
                className={styles.table}
                >
                  <thead>
                    <tr>
                      {Object.keys(tableHeadings).map((title, index) => (
                        <th
                          key={index}
                          className={styles.heading}
                        >
                          {title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {cheapestItems.map((data, rowIndex) => (
                      <tr key={data.material}>
                        {Object.values(tableHeadings).map((key, colIndex) => (
                          key && <td key={colIndex}>
                            {key === "material" ? 
                            <div className={styles.materialDetails}>
                            <span className={styles.infoButton} onClick={() => handleInfoIcon(data)}>
                            <svg stroke="currentColor" fill="#ffffff" stroke-width="0" viewBox="0 0 16 16" height="25px" width="40px" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                            </span>
                              <Image 
                              className={styles.materialPicture}
                              src="#"
                              />
                              </div> 
                              : 
                              <input
                              readOnly
                              className={classNames(styles.resultInput, key === "description" ? styles.textStart : styles.textCenter)}
                              type="text"
                              value={data[key]} 
                              />
                              }
                          </td>
                          
                        ))}
                        <td key={`change-selection-button-${rowIndex}`}>
                          <Button id="change-selection-button"
                            variant='success'
                            className={null}
                            onClick={() => handleChangeSelection(data.material)}
                          >
                            Select
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
              </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
      </Accordion>
    ))}
    <ItemSelectionModal
      tableData={modalData}
      showModal={showModal}
      closeModal={() => { setShowModal(false); }}
      updateItem={updateSelectedItem}
      />
     <ProductInfoModal 
      isProductInfoModalOpen={isProductInfoModalOpen} 
      setProductInfoModalOpen={setProductInfoModalOpen}
      productInfoModalData={productInfoModalData}
      />
      <FilterModal 
        accordionData={accordionData}
        isFilterModalOpen={isFilterModalOpen}
        setFilterModalOpen={setFilterModalOpen}
        setFormattedAccordiondData={setFormattedAccordiondData}
        setSortingActive={setSortingActive}
      />
      <OrderModal
        isOrderModalOpen={isOrderModalOpen}
        setOrderModalOpen={setOrderModalOpen}
        orderConfiguration={orderConfiguration}
      />
     </div>
    </>
  )
}
export default MerchantAccordion
