import React, { useContext } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { AuthContextInterface } from "src/context"
import { AuthContext } from "src/context/AuthContext"
import { OrderModalProps } from "../MerchantAccordionProps"
import Order from "./Order"

const OrderModal = ({ isOrderModalOpen, setOrderModalOpen, orderConfiguration }: OrderModalProps) => {

  const { currentUserConfiguration: { isAbleToOrder, orderMessage } }: AuthContextInterface = useContext(AuthContext);
  
  return (
    <Modal show={isOrderModalOpen} onHide={() => setOrderModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isAbleToOrder ? (
            <>Order placement for {orderConfiguration.merchantName}</>
          ) : (
            <>Order Notification</>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isAbleToOrder ? (
          <Order orderConfiguration={orderConfiguration} />
        ) : (
          <>{orderMessage}</>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOrderModalOpen(false)}>
          Cancel
        </Button>
        {isAbleToOrder && (
          <Button variant="primary" onClick={() => setOrderModalOpen(false)}>
            Send
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
export default OrderModal
