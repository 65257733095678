import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Container, Col, Row, Spinner } from "react-bootstrap";

import Layout from "components/layout";
import SEO from "components/seo";

import ProgressButtons from 'components/BuildersMerchantPage/ProgressButtons';
import { merchantsList } from "src/merchantAPIs/merchantsList";
import { BuildersMerchantsContext } from 'src/context/BuildersMerchantsContext';
import MerchantAccordion from 'components/BuildersMerchantPage/MerchantAccordion';
import AdvertisementBanner from 'components/AdvertisementBanner/AdvertisementBanner';
import { AuthContext } from "src/context/AuthContext";
import { AuthContextInterface } from 'src/context';
import { 
    MerchantAccordionProduct, 
    MerchantAccordionProducts, 
    MerchantAccordionRowData, 
    MerchantAccordionRows 
} from 'components/BuildersMerchantPage/MerchantAccordion/MerchantAccordionProps';

const ComparePage = () => {
    const buildersMerchantsContext = useContext(BuildersMerchantsContext);
    const { value: productSearchItems } = buildersMerchantsContext.productsContext;
    const { currentUserConfiguration }: AuthContextInterface = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);
    const [showItemSelectionModal, setShowItemSelectionModal] = useState(false);
    const [showResultsTable, setShowResultsTable] = useState<boolean>(false);

    const memoizedShowResultTableSetter = useCallback((flag: boolean) => setShowResultsTable(flag), []);

    const [tableResults, setTableResults] = useState<MerchantAccordionRows>({});

    const loadingText = "Retrieving product data…";

    const resultText = "Click on merchants for their products & prices";

    const createApiSearches = (transcriptionSearchData: UnleashedSearchProps[], merchant: Merchant): Promise<ApiSearchResults>[] => {
        return transcriptionSearchData.map((searchDatum) => {
            const data: UnleashedSearchProps = {
                material: searchDatum.material.toLowerCase(),
                quantity: searchDatum.quantity,
                manufacturer: searchDatum?.manufacturer?.toLowerCase(),
                variation: searchDatum?.variation?.toLowerCase(),
            };
    
            return merchant.searchFunction(data);
        });
    }

    const getMerchantResults = async (merchantsList: Merchant[], transcriptionSearchData: UnleashedSearchProps[]): Promise<{
        merchant: string;
        rowData: MerchantAccordionRowData;
    }[]> => {
        const merchantResults = merchantsList.map(async (merchant) => {
            const apiSearches = createApiSearches(transcriptionSearchData, merchant);

            const apiSearchResults = await Promise.all(apiSearches)
                .then((results) => {
                    return results;
                })
                .catch((error) => {
                    console.log(error);
                });
            
            const totalCost = apiSearchResults && apiSearchResults.reduce((a, b) => 
                a + b.cheapestItem.cost, 0
            );
            
            const cheapestItems = apiSearchResults && apiSearchResults.map((result: ApiSearchResults): MerchantAccordionProduct => {
                const cheapestItem: MerchantAccordionProduct = {
                    cost: (result.cheapestItem.cost).toFixed(2),
                    description: result.cheapestItem.description,
                    material: result.cheapestItem.material,
                    quantity: result.cheapestItem.quantity,
                    total: (result.cheapestItem.cost * result.cheapestItem.quantity).toFixed(2),
                }
                
                return cheapestItem;
            });

            const materialProducts = apiSearchResults && apiSearchResults.map((apiSearchResult) => {
                const material: string = apiSearchResult.material;
                
                const products: MerchantAccordionProduct[] = apiSearchResult.results.map((result) => {
                    const product: MerchantAccordionProduct = {
                        cost: (result.cost).toFixed(2),
                        description: result.description,
                        material: result.material,
                        quantity: result.quantity,
                        total: (result.cost * result.quantity).toFixed(2),
                    };

                    return product;
                });

                return {
                    "material": material,
                    "products": products,
                } 
            });

            const accordionProducts: MerchantAccordionProducts = materialProducts.reduce((arr, val) => (
                {
                    ...arr,
                    [val.material]: val.products,
                }),
                {}
            );

            const itemsStocked = cheapestItems.reduce((a, b) => 
                a + (b.quantity ? 1 : 0) , 0
            );

            const totalItems = cheapestItems.length;
            const merchantRowData: MerchantAccordionRowData = {
                cheapestItems: cheapestItems,
                itemsStocked: itemsStocked,
                totalItems: totalItems,
                totalCost: totalCost,
                tableData: accordionProducts,
                modalData: null,
                merchantBrandPicture: merchant.merchantBrandPicture,
                merchantEmail: merchant.merchantEmail
            }

            return {
                "merchant": merchant.merchantName,
                "rowData": merchantRowData,
            };
        });
            
        const res = await Promise.all(merchantResults);
        return res;
    };

    useEffect(() => {
        getMerchantResults(merchantsList, productSearchItems)
            .then((accordionRows) => {
                const merchantAccordionRows: MerchantAccordionRows = accordionRows.reduce((arr, val) => (
                    {
                        ...arr,
                        [val.merchant]: val.rowData,
                    }),
                    {}
                );

                setTableResults(merchantAccordionRows);
                setIsLoading(false);
            });
        },
        [productSearchItems]
    );

    const showItemSelectionModalHandler = () => {
        setShowItemSelectionModal(!showItemSelectionModal);
    }

    return (
        <div style={{ backgroundColor: '#FFFFFF' }}>
            <Layout pageInfo={{ pageName: "compare" }}>
                <SEO title="Results" keywords={[`gatsby`, `react`, `bootstrap`]} />
                <Container fluid className="px-0">
                    <Row className="justify-content-center">
                        <Col xs={10}>
                            <h2 className="display-5 text-center" style={{color:"black"}}>COMPARE</h2>
                        </Col>
                    </Row>
                    {(isLoading && showResultsTable) &&
                        <Row className="justify-content-center">
                            <Col xs={12} className="justify-content-center align-items-center text-center">
                                <p className='text-center'>{loadingText}</p>
                                <Spinner animation="border" className='text-center' />
                            </Col>
                        </Row>}
                    {!!currentUserConfiguration && (!showResultsTable) && <AdvertisementBanner setShowResultsTable={memoizedShowResultTableSetter} />}
                    {(!isLoading && showResultsTable) &&
                        <>                        
                            <Row className="justify-content-center">
                                <Col xs={11} sm={9} md={7} lg={8} className="justify-content-center align-items-center text-center">
                                    <p className='text-center'>{resultText}</p>
                                    <MerchantAccordion
                                        rows={tableResults}
                                        selectItem={showItemSelectionModalHandler}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-4">
                                <Col lg={8} className="justify-content-center align-items-center text-center ">
                                    <ProgressButtons />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </Layout>
        </div>
    )
}

export default ComparePage
