import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import DropdownButton from "react-bootstrap/DropdownButton"
import Dropdown from "react-bootstrap/Dropdown"
import Form from "react-bootstrap/Form"
import styles from "./FilterModal.module.scss"
import {
  FilterModalProps,
  FormattedAccordionRowData,
} from "../MerchantAccordionProps"

const FilterModal = ({
  accordionData,
  isFilterModalOpen,
  setFilterModalOpen,
  setFormattedAccordiondData,
  setSortingActive
}: FilterModalProps) => {

    const defaultSortingOptions = [
        { title: "Cost", isActive: true, sortBy: "Price", options: ["Low to High", "High to Low"], selectedOption: "Low to High" },
        { title: "Is Stocked", isActive: true, sortBy: "Stock", options: ["Least to Most", "Most to Least"], selectedOption: "Least to Most" },
        { title: "Name", isActive: true, sortBy: "From", options: ["A - Z", "Z - A"], selectedOption: "A - Z" },
      ];

    const [sortingConfiguration, setSortingConfiguration] = useState(defaultSortingOptions);

    useEffect(() => {
        const activeSorts = sortingConfiguration.filter(config => config.isActive);
        setSortingActive(!!activeSorts.length)
    }, [isFilterModalOpen])
    

    const handleCheckboxChange = (index: number) => {
        const updatedConfiguration = [...sortingConfiguration];
        updatedConfiguration[index].isActive = !updatedConfiguration[index].isActive;
        if (!updatedConfiguration[index].isActive) {
            updatedConfiguration[index].selectedOption = null;
        }
        setSortingConfiguration(updatedConfiguration);
    };

    const handleDropdownChange = (index: number, option: string) => {
        const updatedConfiguration = [...sortingConfiguration];
        updatedConfiguration[index].selectedOption = option;
        updatedConfiguration[index].isActive = true;
        setSortingConfiguration(updatedConfiguration);
    };

    const sortByPrice = (a, b, selectedOption) => {
        return selectedOption === "High to Low" ? parseFloat(b.merchantTotal) - parseFloat(a.merchantTotal) : parseFloat(a.merchantTotal) - parseFloat(b.merchantTotal);
    };

    const sortByStock = (a, b, selectedOption) => {
        return selectedOption === "Most to Least" ? b.itemsStocked - a.itemsStocked : a.itemsStocked - b.itemsStocked;
    };

    const sortByName = (a, b, selectedOption) => {
        const firstLetterA = a.key.split(' ')[0][0].toLowerCase();
        const firstLetterB = b.key.split(' ')[0][0].toLowerCase();
        return selectedOption === "Z - A" ? firstLetterB.localeCompare(firstLetterA) : firstLetterA.localeCompare(firstLetterB);
    };

    const sortData = (data: FormattedAccordionRowData[]) => {
        const sortedData = [...data];
        const activeSorts = sortingConfiguration.filter(config => config.isActive);
        const sortingOptions = {
            Price: (a, b, selectedOption) => sortByPrice(a, b, selectedOption),
            Stock: (a, b, selectedOption) => sortByStock(a, b, selectedOption),
            From: (a, b, selectedOption) => sortByName(a, b, selectedOption)
        };
        activeSorts.forEach(({ sortBy, selectedOption }) => {
            sortedData.sort((a, b) => sortingOptions[sortBy](a, b, selectedOption));
        });
        setFormattedAccordiondData(sortedData);
    };

    const transformedAccordionData = Object.keys(accordionData)?.map((key) => ({
        key,
        merchantTotal: accordionData[key].cheapestItems.reduce((acc, { total }) => acc + Number(total), 0).toFixed(2) ,
        ...accordionData[key]
      }))
      

  useEffect(() => {
    sortData(transformedAccordionData)
  }, [JSON.stringify(transformedAccordionData)])

  return (
    <Modal show={isFilterModalOpen} onHide={() => setFilterModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Sort by</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {sortingConfiguration.map(
          ({ title, isActive, sortBy, options, selectedOption }, index) => (
            <div key={index} className={styles.optionContainer}>
              <span className={styles.optionTitle}>{title}</span>
              <div className={styles.option}>
                <div className={styles.optionCheck}>
                  <Form.Check
                    checked={isActive}
                    type="checkbox"
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <span>{sortBy}</span>
                </div>
                <DropdownButton
                  className={styles.dropdownButton}
                  title={selectedOption || options[0]}
                >
                  {options.map((option, optionIndex) => (
                    <Dropdown.Item
                      key={optionIndex}
                      onClick={() => handleDropdownChange(index, option)}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </div>
          )
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setFilterModalOpen(false)}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            sortData(transformedAccordionData)
            setFilterModalOpen(false)
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default FilterModal
